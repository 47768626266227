export default [
    {
        path: '/',
        name: 'index',
        component: () => import('@/pages/index/index'),
        redirect: '/home',
        meta: {
            keepAlive: true,
            title: '蓝色县域'
        },
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('@/pages/home'),
                meta: {
                    keepAlive: true,
                    title: '蓝色县域'
                }
            },
            // {
            //     path: '/lxClass',
            //     name: 'lxClass',
            //     component: () => import('@/pages/lxClass'),
            //     meta: {
            //         keepAlive: true,
            //         title: '蓝色县域'
            //     }
            // },
            {
                path: '/mine',
                name: 'mine',
                component: () => import('@/pages/mine/mine'),
                meta: {
                    keepAlive: true,
                    title: '蓝色县域'
                }
            },

        ]
    },
    // 问卷调研
    {
        path: '/answer',
        name: 'answer',
        component: () => import('@/pages/activity/answer/index'),
        meta: {
            keepAlive: true,
            title: '蓝色县域'
        }
    },
    // 问卷调研 / 答题页面
    {
        path: '/answer/detail',
        name: 'detail',
        component: () => import('@/pages/activity/answer/detail'),
        meta: {
            keepAlive: true,
            title: '蓝色县域'
        }
    },
    // 活动
    {
        path: '/activity/NewTask',
        name: 'NewTask',
        component: () => import('@/pages/activity/NewTask'),
        meta: {
            keepAlive: true,
            title: '蓝色县域'
        }
    },
    // 积分说明
    {
        path: '/message',
        name: 'message',
        component: () => import('@/pages/mine/message'),
        meta: {
            keepAlive: false,
            title: '系统消息'
        }
    },
    // 个人信息
    {
        path: '/userInfo',
        name: 'userInfo',
        component: () => import('@/pages/mine/userInfo'),
        meta: {
            keepAlive: false,
            title: '蓝色县域'
        }
    },
    // 积分明细
    {
        path: '/PointsDetails',
        name: 'PointsDetails',
        component: () => import('@/pages/mine/PointsDetails'),
        meta: {
            keepAlive: false,
            title: '蓝色县域'
        }
    },
    // 积分说明
    {
        path: '/integralRule',
        name: 'integralRule',
        component: () => import('@/pages/mine/integralRule'),
        meta: {
            keepAlive: false,
            title: '蓝色县域'
        }
    },

    {
        path: '/blueInterview',
        name: 'blueInterview',
        component: () => import('@/pages/home/blueInterview/index'),
        meta: {
            keepAlive: false,
            title: '蓝色访谈'
        }
    },
    {
        path: '/ninegreatcharms',
        name: 'ninegreatcharms',
        component: () => import('@/pages/home/ninegreatcharms/index'),
        meta: {
            keepAlive: false,
            title: '蓝县风采'
        }
    },
    {
        path: '/ninegreatcharmsdetail',
        name: 'ninegreatcharmsdetail',
        component: () => import('@/pages/home/ninegreatcharms/detail'),
        meta: {
            keepAlive: false,
            title: '蓝县风采'
        }
    },
    {
        path: '/specialized',
        name: 'specialized',
        component: () => import('@/pages/home/specialized/index'),
        meta: {
            keepAlive: false,
            title: '专科有为'
        }
    },
    {
        path: '/bluedoctor',
        name: 'bluedoctor',
        component: () => import('@/pages/home/blueyiguan/index'),
        meta: {
            keepAlive: false,
            title: '县域医管'
        }
    },
    {
        path: '/policynavigation',
        name: 'policynavigation',
        component: () => import('@/pages/home/policynavigation/index'),
        meta: {
            keepAlive: false,
            title: '政策领航'
        }
    },
    {
        path: '/vluecloudlive',
        name: 'vluecloudlive',
        component: () => import('@/pages/home/vluecloudlive/index'),
        meta: {
            keepAlive: false,
            title: '云直播'
        }
    },

    {
        path: '/bluedetailtit',
        name: 'bluedetailtit',
        component: () => import('@/pages/home/detailtit/index'),
        meta: {
            keepAlive: false,
            title: '资讯内容'
        }
    },
    {
        path: '/picturestexts',
        name: 'picturestexts',
        component: () => import('@/pages/home/picturestexts/index'),
        meta: {
            keepAlive: false,
            title: '图文回看'
        }
    },

    {
        path: '/register',
        name: 'register',
        component: () => import('@/pages/register/index'),
        meta: {
            keepAlive: false,
            title: '注册'
        }
    },
    {
        path: '/empower',
        name: 'empower',
        component: () => import('@/pages/register/empower'),
        meta: {
            keepAlive: false,
            title: '授权'
        }
    },
    {
        path: '/titxieyi',
        name: 'titxieyi',
        component: () => import('@/pages/register/titxieyi'),
        meta: {
            keepAlive: false
        }
    },


    // 立即兑换
    {
        path: '/exchangeShop',
        name: 'exchangeShop',
        component: () => import('@/pages/mine/goodShop/exchangeShop'),
        meta: {
            keepAlive: false,
            title: '蓝色县域'
        }
    },
    // 临床医生认证
    {
        path: '/lcDoctorAuth',
        name: 'lcDoctorAuth',
        component: () => import('@/pages/mine/goodShop/lcDoctorAuth'),
        meta: {
            keepAlive: false,
            title: '蓝色县域'
        }
    },
    // 蓝县赋能课说明规则
    {
        path: '/classRules',
        name: 'classRules',
        component: () => import('@/pages/lxClass/classRules'),
        meta: {
            keepAlive: false,
            title: '蓝色县域'
        }
    },
    // 我的课程
    {
        path: '/myClass',
        name: 'myClass',
        component: () => import('@/pages/lxClass/myClass'),
        meta: {
            keepAlive: false,
            title: '蓝色县域'
        }
    },
    //课程详情
    {
        path: '/classDetail',
        name: 'classDetail',
        component: () => import('@/pages/lxClass/classDetail'),
        meta: {
            keepAlive: false,
            title: '蓝色县域'
        }
    },
    //课后试题
    {
        path: '/classQuestions',
        name: 'classQuestions',
        component: () => import('@/pages/lxClass/classQuestions'),
        meta: {
            keepAlive: false,
            title: '蓝色县域'
        }
    },
    // 进入赋能课权限页
    {
        path: '/authLxClass',
        name: 'authLxClass',
        component: () => import('@/pages/lxClass/authLxClass'),
        meta: {
            keepAlive: false,
            title: '蓝色县域'
        }
    },

    // {
    //     path: '/jump',
    //     name: 'jump',
    //     component: () => import('@/pages/jump/index'),
    //     meta: {
    //         keepAlive: false,
    //         title: '蓝色县域'
    //     }
    // },

    // cpp 跳转
    {
        path: '/cppJump',
        name: 'cppJump',
        component: () => import('@/pages/jump/cppJump'),
        meta: {
            keepAlive: false,
            title: '蓝色县域'
        }
    },
    //预约会议填写收货地址的页面
    {
        path: '/meetingAddress',
        name: 'meetingAddress',
        component: () => import('@/pages/jump/meetingAddress'),
        meta: {
            keepAlive: false,
            title: '蓝色县域'
        }
    },
    // 会后问卷 跳转
    {
        path: '/meetingQuesJump',
        name: 'meetingQuesJump',
        component: () => import('@/pages/jump/meetingQuesJump'),
        meta: {
            keepAlive: false,
            title: '蓝色县域'
        }
    },
    {
        path: '/clearCache',
        name: 'clearCache',
        component: () => import('@/pages/jump/clearCache'),
        meta: {
            keepAlive: false,
            title: '蓝色县域'
        }
    },
    // 敬请期待
    {
        path: '/stayTuned',
        name: 'stayTuned',
        component: () => import('@/pages/jump/stayTuned'),
        meta: {
            keepAlive: false,
            title: '蓝色县域'
        }
    },
    

]