import {
    getOauth,
    login
} from "@/request/api.js";
import {
    http_post_params
} from "@/utils/index.js";

const state = {
    wecharUser: {},//用户信息
    siteUser: false, //注册信息
    NewUser: false,//是否是刚注册用户
}

const mutations = {
    setUserInfo(state, data) {
        state.wecharUser = JSON.parse(data)
    },
    setsiteUserinfo(state, data) {
        console.log("已调用", data)
        state.siteUser = data
    },
    NewUser(state, data) {
        state.NewUser = data
    }
}
const actions = {
    getInfo({
        commit
    }, info) {
        // 当异步代码执行成功时，我们才会调用resolve(...), 当异步代码失败时就会调用reject(...)
        return new Promise((resolve, reject) => {
            if (process.env.VUE_APP_TEXT == 'dev') {//本地
                login(http_post_params({
                    openId: info
                })).then(res => {
                    thenFun(res)
                })
            } else {
                getOauth(http_post_params({
                    code: info
                })).then(res => {
                    thenFun(res)
                })
            }
            function thenFun(res) {
                if (res.code == 0 && res.data.wecharUser) {
                    window.sessionStorage.setItem("TokenKey", res.data.token);
                    window.sessionStorage.setItem("appOpenid", res.data.wecharUser.openid);
                    res.data.wecharUser && commit("setUserInfo", JSON.stringify(res.data.wecharUser));
                    window.sessionStorage.setItem("unionid",res.data.wecharUser.unionid)
                    if (res.data.siteUser) {
                        window.sessionStorage.setItem("siteUser", JSON.stringify(res.data.siteUser));
                        commit("setsiteUserinfo", true);
                    }
                    resolve(res.data)
                } else {
                    reject('失败')
                    console.log(res.message)
                }
            }
        })
    }
}

export default {
    state,
    mutations,
    actions,
}