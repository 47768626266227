
//这个就是状态（数据） 统一用vuex 来管理数据
const state = {
    TabPath:'/home'
}

//我们要修改vuex仓库中的state状态数据的话 必须要通过mutations中的方法来操作
const mutations = {
    changeTabPath(state, active) {
        state.TabPath = active
    },
}

//异步操作
const actions = {

}

//格式化返回数据
const getters = {

}

export default {
    state,
    mutations,
    actions,
    getters
}