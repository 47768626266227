import { get, post, put, cut,upload } from './request'
import {http_post_params} from '@/utils/index'

// 获取个人信息
export let login = (p) => get('/api/open/wechat/login?' + p); //本地临时

export let getOauth = (p) => get('/api/open/wechat?' + p);

//分享
export let getSignature = (p) => post('/api/open/wechat/getSignature?' + p);

//分享
export let log = (p) => post('/api/open/site/view' , p);

// 获取医院信息
export let hosList = (p) => get('api/home/hosList?name=' + p);

// 获取职称
export let userTitleList = (p) => get('/api/home/title');

// 获取职称
export let userInfo = () => get('/api/user/userInfo');

// 获取短信验证码
export let sendSmsCode = (p) => get('/api/register/code?'+p);

// 修改用户信息
export let updateUser = (p,data) => post('/api/user/updateUser?code='+p,data);

// 老用户是否首次登录 弹出引导页
export let isFirstLogin = () => get('/api/home/isFirstLogin');

// 老用户首次登录 完成了引导页
export let addLoginPoint = () => get('/api/home/addLoginPoint');

// 统计视频 || 文章管看进度
export let statisticsTime = (data) => post('/api/content/statisticsTime',data);


// 补充手机号
export let savePhone = (p) => get('/api/home/savePhone?'+p);
// 是否补充手机号
export let isBox = (p) => get('/api/home/isBox');

// 参与调研问卷重新作答
export let repeatSurvey = (p) => get('/api/user/repeatSurvey',p);
// 获取摘要
export let getAbstract = (p) => get('/api/user/getAbstract',p);
// 标题
export let getTitle = (p) => get('/api/user/getTitle',p);

// 县有好物列表
// 是否展示县有好物
export let isMall = () => get('/api/point/isMall');
// 列表
export let mallList = () => get('/api/point/mallList');
// 兑换信息
export let orderDetail = (p) => get('/api/point/orderDetail',p);
// 兑换
export let orderExchange = (data) => post('/api/point/exchange',data);
// 医生认证信息下拉数据
export let downGetHosInfo = (p) => get('/api/point/getHosInfo',p);
// 省市区数据
export let downGetArea = (p) => get('/api/point/getArea',p);
// 文件上传
export let fileUpload = (p) => upload('/api/upload/upload',p);
// 临床医生认证
export let saveHosInfo = (data) => post('/api/point/saveHosInfo',data);



// 赋能课banner
export let courseBannerList = (p) => get('/api/course/bannerList',p);
// 课程分类
export let courseCategory = (p) => get('/api/course/getCategoryList',p);
// 赋能课程列表
export let courseList = (p) => get('/api/course/list',p);
// 课程详情
export let courseDetail = (p) => get('/api/course/byId',p);
// 课程收藏
export let courseCollect = (p) => get('/api/course/saveCollect',p);
// 视频进度保存
export let saveCourseStudy = (p) => get('/api/course/saveCourseStudy',p);
// 我的课程
export let myCourseList = (p) => get('/api/course/userCourseList',p);
// 已学课程数量
export let studyPlanSum = (p) => get('/api/course/studyPlanSum',p);
// 课程状态
export let studyStatus = (p) => get('/api/course/studyStatus',p);
// 试题
export let surveyById = (p) => get('/api/course/surveyById',p);
// 答题
export let saveSurvey = (p) => post('/api/course/saveSurvey?courseId=' + p.id + '&isRepeat=' + p.isRepeat,p.data);
// 获取答题进度
export let getSurveyProgress = (p) => get('/api/course/getSurveyProgress',p)
// 获取倒计时
export let getCountdown = (p) => get('/api/course/getCountdown',p)
// 保存重置时间
export let saveCountdown = (p) => post('/api/course/saveCountdown?' + http_post_params(p),p)

// 我的课程学分兑换
export let saveCredit = (p) => get('/api/course/saveCredit',p);
// 是否有赋能课权限
export let getPopStatus = (p) => get('/api/course/getPopStatus',p);


// 糖医网跳转蓝县塞入openid
export let saveTyByOpenId = (p) => get('/api/open/wechat/saveTyByOpenId',p);
// 绑定糖医网openid
export let saveTyForLx = (p) => get('/api/open/wechat/saveTyForLx',p);

// 会议兑换信息的
export let caseInfoByUser = (p) => get('/api/third/national/caseInfoByUser',p);
// 提交
export let saveCaseHistory = (p) => post('/api/third/national/saveCaseHistory?' + http_post_params(p),p)
// 查看是否兑换过
export let isExchange = (p) => get('/api/third/national/isExchange',p);