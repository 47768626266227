import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './router'
import store from '../stroe/index'
import {
    getUrlParam,
} from "@/utils/index.js";
import{
    saveTyForLx,
    getPopStatus,
} from "@/request/api"
import { Toast } from 'vant/es';
Vue.use(VueRouter)

const router = new VueRouter({
    routes
})

// const whiteList = ['/login', '/regist', '/']
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return originalPush.call(this, to).catch(err => err)
}

router.beforeEach((to, from, next) => {
    if(to.path == '/cppJump' || to.path == '/clearCache'){//cpp 跳转
        next()
    } else {
        if (!window.sessionStorage.getItem("TokenKey")) {
            if (process.env.VUE_APP_TEXT == 'dev') { //本地登录方式
                // 李 okMHI5lmli6PyGEKTCPXeR3g8U9c   张燕 okMHI5mhOSVR1wAnOItRBoVJzMrU  佳瑶 okMHI5iu0OOtgGobySShFJH70Ess
                store.dispatch("getInfo", "okMHI5lmli6PyGEKTCPXeR3g8U9c").then((res) => {
                    resfun(res)
                }).catch(() => {
                    // 用户信息获取异常
                    checkRegister()
                });
            } else {
                const code = getUrlParam("code"); // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
                const local = window.location.href;
                if (code == null || code === "") { //打包
                    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_TEXT == 'test' ? 'wx1cf89a9817fc1ab7' : 'wx06c8f9d4970c1cec'}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`
                } else {
                    store.dispatch("getInfo", code).then((res) => { //打包
                        resfun(res)
                    }).catch(() => {
                        // 用户信息获取异常
                        checkRegister()
                    });
                }
            }
        } else {
            checkRegister()
        }
        // 获取完用户信息 信息异常 校验注册
        async function checkRegister(res) {
            if (window.sessionStorage.getItem("siteUser") && window.sessionStorage.getItem("siteUser") != 'null') { //已注册
                if (to.path == "/picturestexts") {
                    const fkagif = JSON.parse(window.sessionStorage.getItem("siteUser")).occupation
                    if (fkagif == '001' || fkagif == '002' || fkagif == '003' || fkagif == '004' || fkagif == '005' || fkagif == '006' || fkagif == '院长' || fkagif == '副院长' || fkagif == '书记' || fkagif == '副书记' || fkagif == '科室主任' || fkagif == '科室副主任') {
                        next()
                    } else {
                        window.location.href = 'https://wesite.itangyi.com/newsite/?v=2.0.0#/tyhy?trace=LSXY'
                    }
                } else if (to.path == '/lxClass' || to.path == '/classDetail'){
                    //赋能课
                    await getPopStatus().then(res=>{
                        if(res.code == 0){
                            if(res.data.status == '1' || res.data.status == '2' || res.data.status == '3'){
                                // 1积分不足 2 身份问题 3 条件均满足未兑换
                                next('/authLxClass?status=' + res.data.status)
                            } else if(res.data.status == '4'){
                                // 条件均满足已兑换
                                next()
                            }
                        }
                    })
                } else if(to.path == "/vluecloudlive"){
                    // 蓝县云直播
                    if(to.query.openid && to.query.openid != ''){
                        //从糖医网过来的需要绑定下openid
                        await saveTyForLx({openId:to.query.openid}).then(res=>{
                            next()
                        }).catch(err=>{
                            next()
                        })
                    } else {
                        next()
                    }
                } else {
                    next()
                }
            } else { //没注册
                if(to.path == '/meetingQuesJump'){
                    window.sessionStorage.setItem("goTopath",'/meetingQuesJump')
                }
                if(to.path == '/vluecloudlive'){
                    //判断从糖医网过来的
                    if(to.query.openid && to.query.openid != ''){
                        var data = JSON.stringify(to.query)
                        window.sessionStorage.setItem("cloudLiveTywOpenidData",data)
                        next('/empower')
                    } else {
                        next('/register')
                    }
                } else if (to.path == "/register" || to.path == "/empower" || to.path == "/titxieyi") {
                    next()
                } else{
                    next('/register')
                }          
                // if (to.path == "/register" || to.path == "/empower" || to.path == "/titxieyi") {
                //     next()
                // } else {
                //     next('/register')
                // }
            }
        }
        // 登录成功 获取到用户信息
        var resfun = async (res) => {
            console.log(window.sessionStorage.getItem("siteUser"))
            // 判断siteUser是否有值 没值 = 没注册
            if (window.sessionStorage.getItem("siteUser")) {
                if (to.path == "/picturestexts") {
                    const fkagifnext = JSON.parse(window.sessionStorage.getItem("siteUser")).occupation
                    if (fkagifnext == '001' || fkagifnext == '002' || fkagifnext == '003' || fkagifnext == '004' || fkagifnext == '005' || fkagifnext == '006' || fkagifnext == '院长' || fkagifnext == '副院长' || fkagifnext == '书记' || fkagifnext == '副书记' || fkagifnext == '科室主任' || fkagifnext == '科室副主任') {
                        next('/picturestexts')
                    } else {
                        window.location.href = 'https://wesite.itangyi.com/newsite/?v=2.0.0#/tyhy?trace=LSXY'
                    }
                } else if (to.path == '/lxClass' || to.path == '/classDetail'){
                    //赋能课
                    await getPopStatus().then(res=>{
                        if(res.code == 0){
                            if(res.data.status == '1' || res.data.status == '2' || res.data.status == '3'){
                                // 1积分不足 2 身份问题 3 条件均满足未兑换
                                next('/authLxClass?status=' + res.data.status)
                            } else if(res.data.status == '4'){
                                // 条件均满足已兑换
                                next()
                            }
                        }
                    })
                } else if(to.path == "/vluecloudlive"){
                    // 蓝县云直播
                    if(to.query.openid && to.query.openid != ''){
                        console.log('有openid:',to.query.openid)
                        //从糖医网过来的需要绑定下openid
                        await saveTyForLx({openId:to.query.openid}).then(res=>{
                            next()
                        }).catch(err=>{
                            next()
                        })
                    } else {
                        next()
                    }
                } else {
                    if (to.path == "/empower" || to.path == "/register") {
                        next('/home')
                    }else{
                        next()
                    }
                }
            } else {
                if(to.path == '/meetingQuesJump'){
                    window.sessionStorage.setItem("goTopath",'/meetingQuesJump')
                }
                console.log('444',to.path,to.query)
                // 是否链接直接进入授权页
                if(to.path == '/vluecloudlive'){
                    //判断从糖医网过来的
                    if(to.query.openid && to.query.openid != ''){
                        var data = JSON.stringify(to.query)
                        window.sessionStorage.setItem("cloudLiveTywOpenidData",data)
                        next('/empower')
                    } else {
                        next('/register')
                    }
                } else{
                    next('/register')
                }
                
            }
            if (window.location.href.indexOf("&code=") !== -1 || window.location.href.indexOf("?code=") !== -1) {
                let key = window.location.href.indexOf("&code=") !== -1 ? '&code=' : '?code='
                window.location.href =
                    window.location.href.split(key)[0] +
                    "#" +
                    window.location.href.split("#")[1];
            }
        }
    }
    store.commit("changeTabPath", to.path)
});

export default router