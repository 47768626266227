<template>
  <div id="app">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  watch: {
    $route(to, from) {
      this.documentTitle = to.meta.title ? to.meta.title : "";
      document.title = this.documentTitle;
      var scrollWrap = document.getElementById('app');
      scrollWrap.scrollTop = 0;
    },
  },
}
</script>

<style>
html,body{
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
</style>
