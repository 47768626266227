import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
// import './rem.js'
// import './.postcssrc.js'
// import './postcss.config.js'
import store from './stroe/index'
import { codeerrr } from '@/utils/code'
import {
  http_post_params
} from "@/utils/index.js";
import '@/plugins/vant'
// Vue.config.productionTip = FALSE

import Share from './utils/cmsutils'
Vue.prototype.$Share = Share

import './assets/index.less'

Vue.prototype.codeerrr = codeerrr
Vue.prototype.http_post_params = http_post_params

// 注册全局跳转微信小程序组件
import wx from 'weixin-js-sdk'
Vue.prototype.wx = wx
Vue.config.ignoredElements = ['wx-open-launch-weapp']

// if (process.env.NODE_ENV =='test'){
  // const Vconsole = require(`vconsole`);
  // const vConsole = new Vconsole();
// }
// export default vConsole

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper);
new Vue({
  router,
  store,

  render: h => h(App),
}).$mount('#app')
