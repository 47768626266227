// 按需全局引入 vant组件
import Vue from 'vue'
import { ImagePreview,Rate,Progress, Badge, Stepper, Tag, Card, SwipeCell, TreeSelect, NavBar, RadioGroup, CheckboxGroup, Checkbox, Switch, Radio, Step, Steps, Tab, Tabs, Toast, Form, Field, Overlay, Icon, Uploader, Picker, Popup, DatetimePicker, Calendar, Button, List, Cell, CellGroup, Tabbar, TabbarItem, PullRefresh, Swipe, SwipeItem, Search, Dialog, Slider, AddressList, AddressEdit ,Notify, Loading,Grid, GridItem,Area,CountDown} from 'vant'
import addressEdit from 'vant/es/address-edit'
import addressList from 'vant/es/address-list'
Vue.use(Button)
Vue.use(Cell)
Vue.use(Badge)
Vue.use(Stepper)
Vue.use(Tag)
Vue.use(Card)
Vue.use(CellGroup)
Vue.use(List)
Vue.use(SwipeCell)
Vue.use(NavBar)
Vue.use(TreeSelect)
Vue.use(Tabbar).use(TabbarItem)
Vue.use(PullRefresh);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Search);
Vue.use(Calendar);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Uploader);
Vue.use(Icon);
Vue.use(Overlay);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(Toast);
Vue.use(Switch);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Slider);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Step);
Vue.use(Steps);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(ImagePreview);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(addressEdit);
Vue.use(addressList);
Vue.use(Notify);
Vue.use(Rate);
Vue.use(Progress)

const VanLoading = Loading;
Vue.use(VanLoading);
Vue.use(Area)
Vue.use(CountDown)