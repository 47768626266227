import Vue from 'vue'
import Vuex from 'vuex'
import home from './home/home'
import login from './login/index'

Vue.use(Vuex)

//这个就是状态（数据） 统一用vuex 来管理数据
const state = {

}

//我们要修改vuex仓库中的state状态数据的话 必须要通过mutations中的方法来操作
const mutations = {

}

//异步操作
const actions = {

}

//格式化返回数据
const getters = {

}

const modules = {
    home,
    login
}

// 实例化仓库
const store = new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules
})

export default store