// 获取url[code]参数
export function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}
// post接口拼接
export function http_post_params(data) {
    var key = Object.keys(data)
    var val = Object.values(data)
    var str = ''
    for (var i = 0; i < key.length; i++) {
        // if (val[i]) {
            str += `${key[i]}=${val[i]?val[i]:''}`
            if (i != key.length - 1) {
                str += '&'
            }
        // }
    }
    return str
}
//节流
export function thorttle(fn, delay = 1000) {
    let lastTime = ''
    let timer = ''
    let interval = delay
    return function () {
        let args = arguments
        let nowTime = Date.now()
        if (lastTime && nowTime - lastTime < interval) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                lastTime = nowTime
                fn.apply(this, args)
            }, interval)
        } else {
            lastTime = nowTime
            fn.apply(this, args)
        }
    }
}
// 校验手机格式
export function checkMobile(mobile) {
    return RegExp(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/).test(mobile);
}