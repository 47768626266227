import wx from "weixin-js-sdk";
import {
    getSignature,
    log
} from "@/request/api.js";
import {
    http_post_params
} from "@/utils/index.js";
export default {
    utilsLOG: function (data) {
        return new Promise((resolve, reject) => {
            log(data).then((res) => {
                resolve(res)
            }).catch((rem)=>{
                reject(rem)
            })
        })

    },
    /**
     * @param {string} logo - 分享图标
     * @param {string} title - 分享标题
     * @param {string} summary - 分享描述
     * @param {string} viewId - 埋点log浏览信息id
     * @param {string} viewType - 埋点log浏览类型
     * @param {string} sourceType - 埋点source浏览类型
     * @param {string} shareLink - 分享卡片进入的链接
     */
    getShareinfo: function (item) {
        //    描述  
        let p = {
            pageUrl: location.href.split("#")[0],
        };
        getSignature(http_post_params(p), {
            errcode: "string",
            errmsg: "string",
            expires_in: "string",
            pageUrl: "string",
            ticket: "string",
        }).then((res) => {
            // console.log(res);
            if (res.code == 0) {
                var f = res.data
                this.shareFun(f.appId, f.timestamp, f.noncestr, f.signature, item.logo, item.title, item.summary, item.viewId, item.viewType, 'dd', item.shareLink, item.sourceType)
            }
        });
    },
    shareFun: function (appid, timestamp, noncestr, signature, logo, title, summary, viewId, viewType, callback, shareLink = "", sourceType) {
        wx.config({
            // debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: appid, // 必填，公众号的唯一标识
            timestamp: timestamp, // 必填，生成签名的时间戳
            nonceStr: noncestr, // 必填，生成签名的随机串
            signature: signature, // 必填，签名，见附录1【必填：通过提供接口获取】
            jsApiList: [
                // 'updateTimelineShareData',
                'checkJsApi',
                'onMenuShareTimeline',
                'onMenuShareAppMessage',
            ]
            // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        wx.error(function (res) {
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            console.log('微信分享签名失败', res);
            //签名失败跳转到错误页面
        });
        wx.ready(function () {
            var newHdUrl = shareLink || location.href;
            var shareFriendCircle = title;
            var shareFriendTitle = title;
            var shareFriendDesc = summary;
            var imgUrl = encodeURI(logo);
            wx.checkJsApi({
                jsApiList: ['checkJsApi', 'onMenuShareTimeline', 'onMenuShareAppMessage','updateAppMessageShareData','updateTimelineShareData'],
                success: function () {
                    // console.log(res)
                }
            });
            wx.onMenuShareTimeline({
                title: shareFriendCircle, // 分享标题【必填】
                link: newHdUrl, // 分享链接【必填】
                imgUrl: imgUrl, // 分享图标【必填】
                success: function () {
                    // 用户确认分享后执行的回调函数
                    if (viewId && viewType) {
                        let p = {
                            sourceType: "SHARE",
                            viewId,
                            viewType,
                        };
                        log(p).then((res) => {
                            if (res.code == 0) {
                                console.log("success");
                            }
                        });
                    }
                    callback();
                },
                cancel: function () {
                }
            });
            wx.onMenuShareAppMessage({
                title: shareFriendTitle, // 分享标题【必填】
                desc: shareFriendDesc, // 分享描述【必填】
                link: newHdUrl, // 分享链接【必填】
                imgUrl: imgUrl, // 分享图标【必填】
                type: 'link', // 分享类型,music、video或link，不填默认为link【必填】
                dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                success: function () {
                    // 用户确认分享后执行的回调函数
                    if (viewId && viewType) {
                        let p = {
                            sourceType: "SHARE",
                            viewId,
                            viewType,
                        };
                        log(p).then((res) => {
                            if (res.code == 0) {
                                console.log("success");
                            }
                        });
                    } 0
                },
                cancel: function () {
                }
            });
        });
    },
    // 订阅
    subscribeTo: function () {
        let p = {
            pageUrl: location.href.split("#")[0],
        };
        getSignature(http_post_params(p), {
            errcode: "string",
            errmsg: "string",
            expires_in: "string",
            pageUrl: "string",
            ticket: "string",
        }).then((res) => {
            if (res.code == 0) {
                var f = res.data
                console.log(f)
                // window.location.replace(`https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm&appid=wx858ea354ef6585da&scene=1000&template_id=bNFHEgcL6O5f-p4DusS8eedZXc8YfJwN391DbnP4DEI&redirect_url=${encodeURIComponent(window.location.href)}&reserved=test#wechat_redirect`)
                wx.config({
                    // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: f.appId, // 必填，公众号的唯一标识
                    timestamp: f.timestamp, // 必填，生成签名的时间戳
                    nonceStr: f.noncestr, // 必填，生成签名的随机串
                    signature: f.signature, // 必填，签名，见附录1【必填：通过提供接口获取】
                    jsApiList: [
                        // 'updateTimelineShareData',
                        'checkJsApi',
                        'onMenuShareTimeline',
                        'onMenuShareAppMessage',
                    ],
                    // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                    openTagList: ['wx-open-subscribe']
                });
                wx.ready(function () {
                    // 订阅
                    wx.openSubscribe({
                        success: function (res) {
                            // 订阅成功
                            console.log(res)
                        },
                        fail: function (res) {
                            // 用户取消订阅
                            console.log(res)
                        },
                        trigger: function (res) {
                            
                        }
                    })
                })
            }
        });
    }
}