import axios from 'axios'

//开发环境配置
axios.defaults.baseURL = process.env.VUE_APP_CURENV
//请求超时时间
axios.defaults.timeout = 100000;
//配置请求头
axios.defaults.headers.post['Content-Type'] = 'application/json'

//请求拦截器
axios.interceptors.request.use(
    config => {
        config.headers['Cache-Control'] = 'no-store'
        // console.log('请求拦截器');
        if (window.sessionStorage.getItem('TokenKey')) {
            config.headers['token'] = window.sessionStorage.getItem('TokenKey')
        }
        // // config.headers.common['Authorization'] = ''
        // config.transformRequest = [function (data) { 
        //     //在请求之前可对data传参进行格式处理
        //     return data
        //  }]

        config.transformRequest = [function (data) {
            //在请求之前可对data传参进行格式处理

            if (config.method === 'post' || config.method === 'put') {
                if(data instanceof FormData){
                    return data
                }
                return JSON.stringify(data)
            }


            return data
        }]
        return config;
    },
    error => {
        return Promise.reject(error)
    }
)

const removeFun = () => {
    window.sessionStorage.removeItem('TokenKey')
    window.location.reload();
}

//响应拦截器
axios.interceptors.response.use(
    res => {
        // console.log('响应拦截器');
        const status = res.status
        if (status === 200) {
            const code = res.data.code
            if (code == 1) {
                //成功数据
                return Promise.resolve(res.data)
            } else if (code == 40100||code == 40101) {
                //登录过期
                removeFun()
            } else {
                //请求成功其他code情况
                return Promise.resolve(res.data)
            }
        } else {
            // 服务器状态码不是200的情况
            return Promise.reject(error)
        }
    }, error => {
        //请求失败
        return Promise.reject(error)
    }
)

/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
                params: params
            })
            .then(res => {
                resolve(res)
            }, err => {
                reject(err.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}


/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res)
            }, err => {
                reject(err.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}

/** 
 * put方法，对应put请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function put(url, params) {
    return new Promise((resolve, reject) => {
        axios.put(url, params)
            .then(res => {
                resolve(res)
            }, err => {
                reject(err.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}

/** 
 * delete方法，对应delete请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function cut(url, params) {
    return new Promise((resolve, reject) => {
        axios.delete(url, params)
            .then(res => {
                resolve(res)
            }, err => {
                reject(err.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}

//文件上传
export function upload(url, params) {
    let config = {
        headers: {
            "Content-Type": "multipart/form-data"
        },
    }
    return new Promise((resolve, reject) => {
        axios.post(url, params, config)
            .then(res => {
                resolve(res)
            }, err => {
                reject(err.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}